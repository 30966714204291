// Only one item MUST have the "default: true" key

module.exports = {
    en: {
        default: true,
        path: `en`,
        locale: `en-US`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
        defaultTitle: `Leshan Streat Foods Collection`,
        defaultDescription: `Leshan is not only big Buddha, but also countless street food.`,
    },
    zh: {
        path: `zh`,
        locale: `zh-CN`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `zh`,
        ogLanguage: `zh_CN`,
        defaultTitle: `乐山美食介绍`,
        defaultDescription: `乐山不只有大佛，还有无数的街边美食。`,
    },
}
