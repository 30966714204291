import React from 'react'

import * as S from './styled'

const Languages = () => {
    return (
        <S.LanguageWrapper>
            <S.LanguageItem>
                <S.LanguageLink to="/" hrefLang="en">
                    en-US
                </S.LanguageLink>
            </S.LanguageItem>
            <S.LanguageItem>
                <S.LanguageLink to="/zh" hrefLang="zh">
                    中文
                </S.LanguageLink>
            </S.LanguageItem>
        </S.LanguageWrapper>
    )
}

export default Languages
