import styled from 'styled-components'
import media from 'styled-media-query'
//import LocalizedLink from '../LocalizedLink'

export const BuyButton = styled.a`
    font-size: 13px;
    text-align: center;
    color: #000;
    text-decoration: none;
    outline: none;
    padding: 12px 30px;
    margin: 0px 30px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 178, 56);
    border-radius: 6px;
    letter-spacing: 1.5px;
`
