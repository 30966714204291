import React from 'react'
import Helmet from 'react-helmet'
import useTranslations from '../useTranslations'
import SocialLinks from '../SocialLinks'

import * as S from './styled'

const Footer = () => {
    const {
        aboutProject,
        seeMorePWA,
        maintainedBy,
        contributeMessage,
    } = useTranslations()

    return (
        <S.FooterWrapper>
            <script
                data-ad-client="ca-pub-0530210725070236"
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            ></script>
            <S.FooterContainer>
                <SocialLinks />
                <p>{aboutProject} . 网站备案号：粤ICP备05005240号</p>
                <p>
                    <a
                        href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
                        target="_blank"
                    >
                        {seeMorePWA}
                    </a>{' '}
                    {maintainedBy}
                    {': '}
                    <a href="https://twitter.com/zhaiduo" target="_blank">
                        @zhaiduo
                    </a>
                    . {contributeMessage}{' '}
                    <a
                        href="https://github.com/diogorodrigues/iceberg-gatsby-multilang"
                        target="_blank"
                    >
                        Github
                    </a>
                    .
                </p>
            </S.FooterContainer>
            <Helmet>
                <script src="https://js.stripe.com/v3/"></script>
            </Helmet>
        </S.FooterWrapper>
    )
}

export default Footer
