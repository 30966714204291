import React from 'react'
import logo from '../../images/logo-small.png'

import * as S from './styled'

const Logo = () => {
    return (
        <S.Logo>
            <img src={logo} alt="Logo" />
        </S.Logo>
    )
}

export default Logo
