import React, { useState } from 'react'
import * as S from './styled'

let timeouteHandler = null
const Checkout = () => {
    const [stripe, setStripe] = useState(null)

    const redirectToCheckout = React.useCallback(
        async event => {
            event.preventDefault()
            if (stripe) {
                const { error } = await stripe.redirectToCheckout({
                    //live:sku_GdXWBBAUYtN6rE
                    //
                    items: [
                        {
                            sku: 'sku_GdYQykujGZCjMI',
                            quantity: 1,
                            //name: 'Buy Me A Coffee',
                        },
                    ],
                    successUrl: `https://lssf.zhaiduo.com/buyOk/`,
                    cancelUrl: `https://lssf.zhaiduo.com/`,
                })
                if (error) {
                    console.warn('checkout Error:', error)
                }
            }
        },
        [stripe]
    )

    React.useEffect(() => {
        //console.log('window.Stripe', window.Stripe)

        timeouteHandler = setTimeout(() => {
            //console.log('window.Stripe==', window.Stripe)
            if (window.Stripe) {
                setStripe(
                    window.Stripe('pk_test_LBeOsXTGOCGfqNhCWmIDdAX700GLX5ohOy')
                )
            }
        }, 1000)

        return () => {
            //console.log('unload window.Stripe')
            clearTimeout(timeouteHandler)
            setStripe(null)
        }
    }, [])
    return stripe ? (
        <S.BuyButton onClick={event => redirectToCheckout(event)}>
            Buy Me A Coffee
        </S.BuyButton>
    ) : null
}

export default Checkout
